$ = jQuery;
/*Functions*/
//event manager
function multilanguageGA(selector, element) {
    var language = $('html').attr('lang');
    $(selector).on('click',function () {
        gtag('event', $(this).attr('data-cas-name'), {
            'event_category': element + '_' + language
        });
    });
}
/**
 * Columns for ul in content
 */
function columns_list() {
    var list = $('.content_block').find('ul:not(.table_of_content)');
    var list_items_count = null;
    list.each(function () {
        list_items_count = $(this).find('li').length;
        if(list_items_count > 5) {
            $(this).addClass('columns_list');
        }
    });
}

/**
 * Hide Content on mobile
 */

function hideContent() {
    if ( $(window).width() < 768 ) {
        $('.bottom_content_second_part').removeClass('active');
        $('.expand_btn span:nth-child(2)').removeClass('active');
        $('.expand_btn span:nth-child(1)').addClass('active');
    } else {
        setTimeout( function () {
            $('.bottom_content_second_part').removeClass('active');
            $('.expand_btn span:nth-child(2)').removeClass('active');
            $('.expand_btn span:nth-child(1)').addClass('active');
        }, 3000 )
    }
}


/**
 * Google Rating
 */
function starsHover() {
    var ratingInner = $(".stars_rating.g_rating .g_rating_inner"),
        ratingInnerWidth = ratingInner.innerWidth(),
        ratingBlock = $(".stars_rating.g_rating"),
        id = $(".g_rating_block .count_number").attr('data-id');
    ratingCount = $(".g_rating_block .count_number span");
    ratingBlock.on("click", function() {
        if (!getCookie('added_rating' + id) && !$(this).data("clicked")) {
            ratingCount.text(parseInt(ratingCount.text()) + 1);
            console.log(ratingCount.text());
            var data = {
                'action': 'add_rating_count',
                'count': ratingCount.html(),
                'id': id
            };
            $.ajax({
                url: '/wp-admin/admin-ajax.php',
                data:data,
                type:'POST',
                success:function(data){
                    if( data ) {
                        ratingCount.text(data);
                    }
                }
            });
        }
        setCookie('added_rating' + id,'true',10);
        $(this).data("clicked", !0)
    });
    if(!getCookie('added_rating' + id) && !$(this).data("clicked")){
        ratingBlock.on("mousemove", function(e) {
            if (!$(this).data("clicked")) {
                var ratingInnerWidth = e.clientX - $(this).offset().left;
                ratingInner.css("width", ratingInnerWidth)
            }
        });
        ratingBlock.on("mouseleave", function(e) {
            ratingInner.css("width", ratingInnerWidth)
        })
    }
}
/*Scroll top*/
function showScrollTop() {
    if ($(this).scrollTop() > 100) {
        $("#to_top").addClass('active');
    }
    else {
        $("#to_top").removeClass('active');
    }
}

/*Redirection Button*/
function toReferral() {
    var btn = $('[data-casino]');
    btn.on('click',function (e) {
        var a = $(this),
            id = a.attr('data-casino'),
            bettingID = '/go-to/?id='+id+'';
        if(bettingID){
            window.open(bettingID);
        }
        else return false;
    });
}



$(document).ready(function () {
    "use strict";
    var siteBody = $("body");
    /*Init*/
    siteBody.addClass(currentBrowser()).addClass(detectmob());
    showScrollTop();
    columns_list();
    $(window).scroll(function () {
        showScrollTop();
    });
    if($(".g_rating")[0]){
        starsHover();
    }
    toReferral();
    /*Events*/

    //hide content
    hideContent();


    /*Scroll top Click*/
    $("#to_top").on("click","a", function (event) {
        event.preventDefault();
        //var id  = $(this).attr('href'),
        //top = $(id).offset().top;
        //$('html, body').animate({scrollTop: top}, 1000);
        $('html, body').animate({scrollTop: 0}, 1000);
    });

    /*Menu*/
    $("#menuOpen button.hamburger").on('click',function (e) {
        $('.menu_blocks').toggleClass("opened");
        siteBody.toggleClass("menu_opened");
        $(this).parent().toggleClass("opened");
        $(this).toggleClass('is-active');

            var body = $('body'), html = $('html'), scroll;
            if (siteBody.hasClass('menu_opened')) {
                var width = body.css('width');
                scroll = window.pageYOffset;
                html.css({ 'overflow': 'hidden'});
                body.css({ 'position': 'fixed', 'top': -scroll, 'max-width' : width});
            } else {
                scroll = -parseInt(body.css('top'));
                html.css({ 'overflow': '' });
                body.css({ 'position': '', 'top': '', 'max-width': '' });
                window.scrollTo(0, scroll);
            }
    });


    /*LANG LIST*/
    $(".lang__menu").on('click', function(e){
        e.stopPropagation();
        $('.lang__menu').not($(this)).removeClass('active');
        $(this).toggleClass('active');
    });

    $(document).on('click', function() {
        $('.lang__menu').removeClass('active');
    });

    $(".lang__list").on('click', function(e){
        e.stopPropagation();
    });

    $('#mainMenu li.menu-item-has-children:not(.pll-parent-menu-item)').append('<span class="subMenuButton i-angle-down"></span>');
    $('.subMenuButton').on('click',function () {
        var t = $(this);
        if(t.hasClass('open')) {
            t.removeClass('open').prev().slideUp(300);
        } else {
            t.removeClass('open').prev().slideDown(300);
            t.addClass('open');
        }
    });
    //Menu Close
    /*$(document).mouseup(function (e){
        var div = $("#mainMenu,#menuOpen");
        if (!div.is(e.target)
            && div.has(e.target).length === 0) {
            div.removeClass('opened');
            $('.hamburger').removeClass('is-active');
            siteBody.removeClass('menu_opened');
        }
    });*/

    //WPCF7
    $(this).on('click', '.wpcf7-not-valid-tip', function () {
        $(this).prev().trigger('focus');
        $(this).fadeOut(500, function () {
            $(this).remove();
        });
    });
    //Privacy
    if(!getCookie('privacy')){
        var privacy = $('#privacy');
        privacy.show();
    }
    $('#privacy .privacy_close').on('click',function () {
        $('#privacy').addClass('hide');
        setCookie('privacy','true',1);
    });

    //Slider
    $('.games_slider .owl-carousel').owlCarousel({
        loop:true,
        margin:30,
        nav:false,
        lazyLoad: true,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:1
            },
            480:{
                items:2
            },
            650:{
                items:3
            },
            767:{
                items:4
            }
        }
    });
    
    //Expand Button
    var expand = $('.expand_btn span');
    expand.on('click',function(){
        $(this).parent().prev().toggleClass('active');
        expand.toggleClass('active');
    });

    /**
     * Table of content
     */
    if($('.content_block h2')[0]) {

        var toc = '<div class="table_of_content_block">\n' +
            '        <h4>'+ phpObject.toc_label +'</h4>\n' +
            '        <ul class="table_of_content"></ul>\n' +
            '    </div>\n';

        $('.content_block > div').prepend(toc);

        $('.table_of_content').toc({
            content: $('.content_block'),
            headings: 'h2'
        });
        $('.table_of_content a').on("click", function (event) {
            event.preventDefault();

            if ( !$('.bottom_content_second_part').hasClass('active') ){
                $('.expand_btn span:nth-child(1)').removeClass('active');
                $('.expand_btn span:nth-child(2)').addClass('active');
                $('.bottom_content_second_part').addClass('active');
            }
            var id  = $(this).attr("href");
            var top = $(id).offset().top - 80;
            $('html, body').animate({scrollTop: top}, 1000);
        });
    }
    //GA
    $(window).on('load',function () {
        //home
        multilanguageGA($('.page-template-tpl-front-page .casino_list [data-casino]'),'home-visit-button');
        //single casino
        //related table
        multilanguageGA($('.page-template-tpl-casino .casino_list [data-casino]'),'single-casino-related-visit-button');
        //top button
        multilanguageGA($('.casino_top_info [data-casino]'),'single-casino-top-visit-button');
        //games slider
        multilanguageGA($('.games_slider [data-casino]'),'games-slider-visit-button');
    });
});
